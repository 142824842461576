
import './App.css';
import './bubbles.css';
import React from "react";
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import CardActionArea from '@mui/material/CardActionArea';
import AspectRatio from '@mui/joy/AspectRatio';
import Typography from '@mui/material/Typography';
import { BrowserRouter, Routes, Route } from "react-router-dom";



const drawerWidth = 240;
const navItems = [
  { name: 'GitHub', link: "https://github.com/michael94ellis/" },
  { name: 'LinkedIn', link: "https://www.linkedin.com/in/michael94ellis/" },
  { name: 'Medium', link: "https://medium.com/@michaelrobertellis" },
  { name: 'Contact', link: "mailto:michael94ellis@gmail.com" }
];
const cards = [
  { name: 'TimeDraw', link: "https://apps.apple.com/us/app/timedraw/id1603772416", description: "Visualize your time.", imgLink: "https://is4-ssl.mzstatic.com/image/thumb/Purple116/v4/74/cc/22/74cc2235-8f80-3343-fb60-88ee052921dd/AppIcon-1x_U007emarketing-0-10-0-85-220.png/230x0w.png", appstore: true },
  { name: 'iTello', link: "https://apps.apple.com/us/app/itello/id1601051980", description: "A SwiftUI Drone Controller", imgLink: "https://is5-ssl.mzstatic.com/image/thumb/Purple116/v4/25/01/0d/25010dee-9c93-b031-bf0f-d6ee0a2ce59b/AppIcon-1x_U007emarketing-0-7-0-85-220.png/230x0w.png", appstore: true },
  { name: 'Quick Menu Calendar', link: "https://apps.apple.com/us/app/quick-menu-calendar/id1594094974", description: "A MacOS calendar toolbar app", imgLink: "https://is5-ssl.mzstatic.com/image/thumb/Purple126/v4/bd/58/59/bd58595a-9f22-beac-ee75-af212a88b9d9/AppIcon-0-85-220-4-2x.png/230x0w.png", appstore: true },
  { name: 'SwiftUIJoystick', link: "https://github.com/michael94ellis/SwiftUIJoystick", description: "A SwiftUI Joystick package", imgLink: "https://github.com/michael94ellis/SwiftUIJoystick/raw/main/Readme_Assets/joystick-nice.gif", badges: true },
  { name: 'Quibblin', link: "https://quibbl.in", description: "A web app to joke with friends. (may take ~30 seconds to spin up server)", imgLink: "quibblin.png", badges: false },
]

function App() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Menu
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton sx={{ textAlign: 'center' }} component="a" href={item.link}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const navBar = (
    <AppBar position='sticky' sx={{ backgroundColor: '#4a5366', boxShadow: 'none', py: 2 }} >
      <Stack direction="row"
        justifyContent="space-between"
        sx={{ display: { xs: 'none', lg: 'flex' }, px: { sm: 10, md: 30 } }}>
        <Button sx={{ color: '#fff' }} href='/'>
          <Typography variant="h5">
            Michael R. Ellis
          </Typography>
        </Button>
        <Stack spacing={8} direction="row"
          justifyContent="space-between">
          {navItems.map((item) => (
            <Button key={item.name} sx={{ color: '#fff' }} href={item.link}>
              <Typography variant="h5">
                {item.name}
              </Typography>
            </Button>
          ))}
        </Stack>
      </Stack >
      <Stack direction="row"
        justifyContent="space-between"
        sx={{ display: { xs: 'flex', sm: 'flex', md: 'flex', lg: 'none' }, justifyContent:'flex-end', px: 6 }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerToggle}
        >
          <Typography variant="h6">
            Michael Ellis
          </Typography>
          <MenuIcon />
        </IconButton>
      </Stack>
      <Box component="nav">
        <Drawer
          anchor='right'
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: {},
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </AppBar >
  );

  function mainContent() {
    return (
      <div>
        <Box sx={{ px: { xs: 1, sm: 4, md: 20  } }}>
          <Stack  direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-around" className='intro-container' >
            <Typography variant='h3' sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'inline' }, p: 3 }}>
              I'm Michael Ellis, a software engineer with a passion for learning and making things.
            </Typography>
            <Typography variant='h3' sx={{ display: { xs: 'none', sm: 'none', md: 'inline', lg: 'none' }, p: 2 }}>
              I'm Michael Ellis, a software engineer.
            </Typography>
            <Typography variant='h4' sx={{ display: { xs: 'inline', sm: 'inline', md: 'none', lg: 'none' }, p: 2 }}>
              I'm Michael Ellis, a software engineer.
            </Typography>
            <AspectRatio 
              sx={{
                flexBasis: { xs: 1800, sm: 1500, md: 2000 },
                borderRadius: '34px',
                overflow: 'none',
                maxHeight:  { xs: '100px', sm:'650px' },
                width: '100vw',
                p: 2,
                display: { xs: 'none', sm: 'inline' }
              }}
            >
              <img alt="Michael Ellis and wife, Megan" src="portrait.jpg" />
            </AspectRatio>
          </Stack>
          <Box className='intro-container'>
            <Typography variant="h5" sx={{ pt: 4 }}>
              Check out some of my projects
            </Typography>
          </Box>
          <Container sx={{ py: 12 }} >
            <Grid container spacing={4}>
              {cards.map((card) => (
                <Grid item key={card.name} xs={12} sm={12} md={6} lg={4}>
                  <Card sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: { xs: 'row', md: 'column' }, alignItems: 'center', backgroundColor: 'white' }} >
                    <CardActionArea href={card.link}
                      sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: { xs: 'row', md: 'column' } }}>
                      <CardMedia
                        sx={{ height: '100%', width: '100%', display: { xs: 'inline-block', sm: 'block' } }}
                        component="img"
                        image={card.imgLink}
                        alt="project image"
                      />
                      <CardContent sx={{ display: { xs: 'inline-block', sm: 'block' }, width: { xs: '60%', sm: '100%' }, height: { xs: '100%', sm: '50%' } }}>
                        <Typography variant="h5">
                          {card.name}
                        </Typography>
                        <Typography>
                          {card.description}
                        </Typography>
                      </CardContent>
                      <Box sx={{ display: { xs: 'none', md: 'flex' }, width: '100%', height: '50%', alignItems: 'flex-end', justifyContent: 'center' }}>
                        {card.badges &&
                          <CardMedia
                            sx={{ width: '60%', display: 'block', pb: 2 }}
                            component="img"
                            image='https://img.shields.io/badge/SPM-compatible-green'
                            alt="project image"
                          />
                        }
                        {card.appstore &&
                          <CardMedia
                            sx={{ width: '80%', display: 'block', pb: 1 }}
                            component="img"
                            image='/appstore.png'
                            alt="project image"
                          />
                        }
                      </Box>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
          <Box sx={{ mx: 4, my: 2, display: { xs: 'none', sm: 'inline-block' } }}>
            {/* Bubbles */}
            <div class="bubbles">
              <div class="bubble"></div>
              <div class="bubble"></div>
            </div>
          </Box>
        </Box>
      </div >
    );
  }

  return (
    <Box className="App">
      {navBar}
      <Box className="bgpage" sx={{ borderRadius: { xs: '0px 0px 75px 0px', sm: '0px 0px 175px 0px' } }}>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={mainContent()}>
            </Route>
          </Routes>
        </BrowserRouter>
      </Box>
      <footer >
        <Stack direction="row"
          justifyContent="space-between"
          sx={{ px: { xs: 2, sm: 10, md: 30 }, py: 4 }}>
          <Typography>
            Michael Ellis <br /><br />+1 (919) 610-4365 <br />michael94ellis@gmail.com
          </Typography>
          <Typography>
            Remote only <br /><br /> Raleigh, NC USA&nbsp; <br />US Citizen
          </Typography>
        </Stack>
      </footer>
    </Box >
  );
}

export default App;
